import axios from "axios";
import { defineStore } from "pinia";
import { ref } from "vue";

import type { components as OpenAPI } from "@/autogen/openapi";
import Urls from "@/autogen/urls";
import { Selection } from "@/components/Layout/ListView/lib";
import api from "@/lib/api/base";
import { ok } from "@/lib/openapi";

export type CreateParams = { [key: string]: any } & {
  selection: Selection<{ id: string }>;
  action_type: OpenAPI["schemas"]["BulkActionType"];
};

export const create = async (
  input: CreateParams
): Promise<OpenAPI["schemas"]["BulkAction"]> => {
  const { selection, action_type, ...rest } = input;

  let ids: string[];
  switch (selection.mode) {
    case "all":
      ids = ["all"];
      break;
    case "all_except":
      ids = selection.items.length
        ? selection.items.map((item) => `-${item.id}`)
        : ["all"];
      break;
    case "some":
      ids = selection.items.map((item) => item.id);
      break;
  }

  // Remove selection and type information from data.
  const payload = {
    type: action_type,
    metadata: {
      ids,
      ...rest,
    },
  };

  const data = await ok(
    api.post("/bulk_actions", {
      body: payload,
    })
  );

  return data;
};

export const retrieve = async (
  id: string
): Promise<OpenAPI["schemas"]["BulkAction"]> => {
  const response = await axios.get(Urls["api:retrieve_bulk_action"](id));
  return response.data;
};

export const useStore = defineStore(
  "bulk-actions",
  () => {
    const bulkActions = ref<
      (OpenAPI["schemas"]["BulkAction"] & { onSuccess?: () => void })[]
    >([]);
    const isCreatingBulkAction = ref(false);

    const draft = ref<CreateParams | null>(null);
    const setDraft = (data: CreateParams | null): void => {
      draft.value = data;
    };

    const createBulkAction = async ({
      data,
      onSuccess,
    }: {
      data: CreateParams;
      onSuccess: () => void;
    }): Promise<void> => {
      isCreatingBulkAction.value = true;
      const bulkAction = await create(data);
      bulkActions.value.push({
        ...bulkAction,
        onSuccess,
      });
      isCreatingBulkAction.value = false;
    };

    const retrieveBulkAction = async (id: string): Promise<void> => {
      const bulkAction = await retrieve(id);
      bulkActions.value = bulkActions.value.map((ba) =>
        ba.id === id
          ? {
              ...ba,
              ...bulkAction,
            }
          : ba
      );
    };

    return {
      draft,
      setDraft,
      bulkActions,
      createBulkAction,
      isCreatingBulkAction,
      retrieveBulkAction,
    };
  },
  {
    broadcast: {
      omit: ["draft"],
    },
  }
);
